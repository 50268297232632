@import '../../../styles/colors_v2.scss';

.headerDeliveryText {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  cursor: pointer;
  max-width: 100%;
  white-space: nowrap;

  &:hover {
    .selfCall {
      color: $black;
    }
  }

  img {
    margin-right: 12px;
  }

  .selfCall {
    color: $white-aluminum;
  }

  .fattyText {
    color: $black;
    font-weight: 500;
    position: relative;
    padding: 0 5px 0 4px;

    &::after {
      position: absolute;
      content: ' ';
      padding: 1px;
      background-color: $black;
      top: 50%;
      right: 0;
      border-radius: 100%;
    }
  }
}

.street {
  max-width: 350px;
  margin-left: 5px;
  color: $black;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}
