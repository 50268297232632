@import '../../styles/colors_v2.scss';
@import '../../styles/breakpoints.scss';
@import 'src/styles/z_index';

.popupMenu {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $popup-modal;
  background-color: rgba(0, 0, 0, 0.4);
}

.wrapper {
  z-index: $modal-GEO;
  position: fixed;
  left: 0%;
  bottom: 0;
  width: 100%;
  height: 100%;
  height: 90dvh;

  box-sizing: border-box;

  @media (min-width: $sm) {
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    height: 100%;
    max-height: 536px;
    max-width: 962px;
    padding: 0 20px;
  }
}

.geoContainer {
  border-radius: 16px 16px 0 0;
  padding: 24px 20px;
  background-color: $white;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: $sm) {
    justify-content: flex-start;
    padding-right: 520px;
    border-radius: 16px;
    position: relative;
  }
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $sm) {
    margin-top: 12px;
  }
}

.title {
  font-weight: 700;
  font-size: 24px;

  @media (min-width: $sm) {
    font-size: 32px;
  }
}

.close {
  padding: 0;

  @media (min-width: $sm) {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 1;
  }
}

.subTitle {
  margin-top: 8px;
  font-size: 14px;
  width: 100%;

  @media (min-width: $sm) {
    font-size: 16px;
    margin-top: 12px;
  }
}

.map {
  margin-top: 8px;
  width: 100%;
  height: 200px;

  @media (min-width: $sm) {
    position: absolute;

    top: 0;
    right: 0;
    height: 100%;
    margin: 0 0 0 20px;
    width: 500px;
  }

  :global {
    .ymaps-2-1-79-map ymaps,
    .ymaps-2-1-79-map ymaps:after,
    .ymaps-2-1-79-map ymaps:before {
      border-radius: 12px;

      @media (min-width: $sm) {
        border-radius: 0;
      }
    }
  }
}

.selectCity {
  box-sizing: border-box;
  width: 100%;
  margin-top: 8px;
  background-color: $white-transparent;
  border-radius: 12px;
  border: none;
  margin: 8px 0;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: $telegray;
  }

  @media (min-width: $sm) {
    margin: 12px 0;
  }
}

.selectAddress {
  margin-top: 8px;

  @media (min-width: $sm) {
    margin-top: 0;
  }
}

.buttonDeliverHere {
  width: 100%;

  padding: 16px 0;
  font-weight: 600;
  border-radius: 12px;
  font-size: 14px;
  box-sizing: border-box;
  background-color: $saffron-yellow;
  color: $black;

  &:disabled {
    background-color: $white-transparent;
    color: $white-aluminum;
  }

  @media (min-width: $sm) {
    font-size: 16px;
    margin-bottom: 0;
  }
}

.isMobileSelectAddress {
  margin: auto 0 40px 0;

  @media (min-width: $sm) {
    margin: auto 0 0 0;
  }
}

.inputContainer {
  position: relative;

  @media (min-width: $sm) {
    margin-top: 16px;
  }
}

.mapButton {
  border-left: 1px solid $gainsborough;
  position: absolute;
  top: 88px;
  right: 10px;
  color: $dark-bluishGreen;
  font-size: 14px;
  padding-left: 10px;
  max-height: 20px;
}

.deleteButton {
  position: absolute;
  top: 70px;
  right: 15px;
  padding: 0;

  img {
    width: 16px;
    height: 16px;
  }
}

.deleteButtonMap {
  position: absolute;
  top: 72px;
  right: 75px;

  img {
    width: 16px;
    height: 16px;
  }
}

.addressContainer {
  margin-top: 12px;
}

.automaticallySelectPoint {
  display: flex;
  align-items: center;
  padding: 0;

  span {
    font-size: 14px;
    color: $dark-bluishGreen;
    margin-left: 8px;
  }
}

.searchAddress {
  margin-top: 12px;
  display: none;

  @media (min-width: $sm) {
    display: block;
  }
}

.searchAddressIsMobile {
  margin-bottom: 12px;
  display: block;

  @media (min-width: $sm) {
    display: none;
  }
}
