@import '../../../styles/colors_v2.scss';
@import '../../../styles/breakpoints.scss';
@import 'src/styles/z_index';

.popupMenu {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $popup-modal;

  background-color: rgba(0, 0, 0, 0.4);
}

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-popup-modal;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  @media (min-width: $xs) {
    top: -20px;
  }
}

.modal {
  z-index: $modal-farmer-registration;
  background-color: $white;
  position: fixed;
  height: 500px;
  overflow: auto;
  bottom: 0;
  border-radius: 16px 16px 0 0;
  margin-top: 42px;
  padding: 16px;

  @media (min-width: $xs) {
    border-radius: 16px;
    background-color: $white;
    padding: 32px 20px 20px 20px;
    bottom: auto;
    position: absolute;
    top: 5%;
    height: auto;
    overflow: visible;
  }
}

.header {
  display: flex;
  align-items: flex-start;

  @media (min-width: $sm) {
    position: relative;
  }
}

.headerCloseButton {
  flex-shrink: 0;
  padding: 0;

  @media (min-width: $sm) {
    position: absolute;
    top: -15px;
    right: 0;
  }
}

.headerTitle {
  font-weight: 700;
  font-size: 24px;

  @media (min-width: $sm) {
    font-size: 32px;
  }
}

.headerSubtitle {
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;

  @media (min-width: $sm) {
    font-size: 16px;
    margin-top: 12px;
  }
}

.farmInformationInputBlock {
  width: 100%;
}

.farmInformationContainer {
  margin-top: 20px;

  @media (min-width: $sm) {
    margin-top: 24px;
  }
}

.farmInformationTitle {
  font-weight: 600;
}

.farmInformationInputBlock {
  margin-top: 12px;
}

.aboutFarm {
  margin-top: 8px;
}

.adressFarm {
  margin-top: 8px;
}

.productsFarm {
  margin-top: 8px;
}

.productsFarmInput {
  padding-top: 40px;
  padding-bottom: 50px;
  width: 100%;
}

.productsVolumeFarm {
  margin-top: 8px;

  &::placeholder {
    white-space: pre-wrap;
  }
}

.productsVolumeFarmInput {
  padding-bottom: 30px;
}

.contactInformationCheckbox {
  margin-top: 16px;
}

.customCheckboxPrivacyPolicy {
  display: none;
  max-width: 150px;

  & + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    margin-left: 0px;
    line-height: 21px;
  }

  & + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: -2.5px;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    border: 1.5px solid $gainsborough;
    border-radius: 5px;
  }

  &:checked + label:after {
    content: '✔';
    position: absolute;
    left: 6px;
    top: 9.5px;

    font-size: 17px;
    color: $black;
    transform: translateY(-50%);
  }
}

.customCheckbox {
  display: none;
  max-width: 150px;

  & + label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
  }

  & + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: -2.5px;
    box-sizing: border-box;
    width: 24px;
    height: 24px;
    border: 1.5px solid $dark-bluishGreen;
    border-radius: 67px;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 6px;
    top: 3.5px;
    width: 12px;
    height: 12px;
    border-radius: 90px;
    background-color: $dark-bluishGreen;
  }

  @media (min-width: $sm) {
    & + label:before {
      top: -3.5px;
    }

    &:checked + label:after {
      top: 3px;
    }
  }
}

.availabilityCertification {
  margin-top: 24px;
}

.availabilityCertificationTitle {
  font-weight: 600;
}

.availabilityCertificationYes {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.availabilityCertificationNo {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.imgFarmsContainer {
  margin-top: 24px;
}

.imgFarmsTitle {
  font-weight: 600;
}

.selectedImgBlock {
  margin-top: 12px;
  padding: 16px 12px;
  border: 1px dashed $light-telegray;
  border-radius: 12px;
}

.selectImgButton {
  padding: 0;
  display: flex;
  align-items: center;

  span {
    margin-left: 3px;
    color: $dark-bluishGreen;
    font-weight: 500;
  }
}

.selectedImgText {
  margin-top: 5px;
  font-size: 12px;
  color: $telegray;
  line-height: 17px;
}

.priceListContainer {
  margin-top: 24px;
}

.priceListTitle {
  font-weight: 600;
}

.selectedPriceListBlock {
  margin-top: 12px;
  padding: 16px 12px;
  border: 1px dashed $light-telegray;
  border-radius: 12px;
}

.selectPriceListButton {
  padding: 0;
  display: flex;
  align-items: center;

  span {
    margin-left: 3px;
    color: $dark-bluishGreen;
    font-weight: 500;
  }
}

.selectedPriceListText {
  margin-top: 5px;
  font-size: 12px;
  color: $telegray;
  line-height: 17px;
}

.photosPackagingContainer {
  margin-top: 24px;
}

.photosPackagingTitle {
  font-weight: 600;
}

.photosPackagingInput {
  margin-top: 12px;
}

.selectedPhotosPackagingBlock {
  margin-top: 12px;
  padding: 16px 12px;
  border: 1px dashed $light-telegray;
  border-radius: 12px;
}

.selectPhotosPackagingButton {
  padding: 0;
  display: flex;
  align-items: center;

  span {
    margin-left: 3px;
    color: $dark-bluishGreen;
    font-weight: 500;
  }
}

.selectedPhotosPackagingText {
  margin-top: 5px;
  font-size: 12px;
  color: $telegray;
  line-height: 17px;
}

.contactInformationBlock {
  margin-top: 24px;
}

.contactInformationTitle {
  font-weight: 600;
}

.contactInformationInputs {
  margin-top: 12px;
}

.contactInformationPhone {
  margin-top: 8px;
}

.contactInformationMail {
  margin-top: 8px;
}

.informationFarmer {
  margin-top: 8px;
}

.informationFarmerInput {
  padding-bottom: 50px;
}

.furtherButton {
  width: 100%;
  margin-top: 24px;
  font-size: 14px;
  font-weight: 600;
  padding: 14px 0;
  background-color: $saffron-yellow;
  border-radius: 12px;

  @media (min-width: $sm) {
    max-width: 159px;
    margin: 24px auto 0 auto;
  }
}
