@import '../../styles/colors_v2.scss';
@import '../../styles/breakpoints.scss';
@import '../../styles/z_index.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  border-top: 1px solid $gainsborough;
  padding: 10px 0;
  font-size: 12px;
  color: $white-aluminum;
  z-index: $z-mobile-navigation;

  @media (min-width: $sm) {
    display: none;
  }
}

.menuItems {
  text-align: center;
  padding: 0 11px;
}

.button {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: $white-aluminum;
  position: relative;
}

.buttonActive {
  .text {
    color: $dark-bluishGreen;
  }
}

.icon2 {
  filter: invert(17%) sepia(300%) saturate(8106%) hue-rotate(156deg) brightness(50%) contrast(105%);
}

.productCount {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-size: 9px;
  background-color: $dark-bluishGreen;
  top: -3px;
  left: 6px;
  color: $white;

  width: 12px;
  height: 12px;
  border-radius: 90px;
}
