@import 'src/styles/breakpoints';
@import 'src/styles/colors';

.container {
  position: fixed;
  left: 15px;
  bottom: 15px;
  display: none;
  align-items: center;
  padding: 26px 20px;
  background: $white;
  box-shadow: 0 6px 17px rgba(2, 24, 15, 0.2);
  border-radius: 16px;
  color: $font-black;
  font-size: 14px;
  z-index: 10;

  @media (max-width: ($lg - 1)) {
    bottom: 80px;
    padding: 16px 8px;
    font-size: 12px;

    .image {
      width: 33px;
    }

    .info {
      margin-left: 10px;
    }

    .proceed {
      font-size: 12px;
    }
  }
}

.visible {
  display: flex;
}

.image {
  width: 44px;
}

.info {
  margin-left: 20px;
}

.counts {
  display: flex;

  span {
    margin-left: 5px;
  }
}

.total {
  opacity: 0.7;
}

.proceed {
  margin-top: 15px;
  font-weight: bold;
  font-size: 16px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: $green;
}
