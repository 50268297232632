@import 'src/styles/colors_v2';
@import 'src/styles/breakpoints';
@import 'src/styles/z_index';

.wrapper {
  padding: 0 16px 16px 16px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background-color: $white;
  z-index: $search-mobile;
  bottom: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  white-space: wrap;
  overflow-y: auto;
  overflow-x: hidden;

  @media (min-width: $sm) {
    display: none;
  }
}

.inputBlock {
  display: flex;
  margin-top: 30px;
  align-items: center;
}

.cancelButton {
  padding: 0 0 0 16px;
  font-size: 14px;
  color: $telegray;
}

.buttonClearHistory {
  padding: 0;
}

.historyBlock {
  margin-top: 16px;
}

.historyButtonBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonText {
  color: $telegray;
  font-size: 14px;
}

.historyMenu {
  margin-top: 12px;
}

.historyMenuItem {
  margin-bottom: 20px;
}

.buttonBack {
  padding: 0;
  margin-right: 8px;
}

.additionalTagsBlock {
  margin-top: 12px;
  border-bottom: 1px solid $gainsborough;
  padding-bottom: 16px;
}

.additionalTagsMenuList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.additionalTagsMenuItem {
  font-size: 12px;
  background-color: $periwinkle-crayola;
  border-radius: 8px;
  padding: 8px 10px;
  margin: 0 8px 8px 0;
}

.subcategoriesWrapper {
  margin-top: 16px;
}

.subcategoriesBlock {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.categoryTitle {
  margin-top: 2px;
  color: $white-aluminum;
}

.productWrapper {
  text-align: center;
  display: flex;
  position: relative;
  z-index: 1;
  margin: 16px -8px 0 -8px;
  flex-wrap: wrap;
}

.ProductCardIndents {
  padding: 0 8px 8px 0;
  width: 50%;
  box-sizing: border-box;
}

.showMoreSearch {
  font-weight: 500;
  background-color: $saffron-yellow;
  padding: 14px 15px;
  border-radius: 12px;
  margin: 0 auto;
}

.subcategoriesTitle {
  box-sizing: border-box;
  word-wrap: break-word;
}
