@import 'src/styles/colors_v2';
@import 'src/styles/breakpoints';
@import 'src/styles/z_index';

.popupMenu {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $popup-modal;
  background-color: rgba(0, 0, 0, 0.4);
}

.authorizationWrapper {
  z-index: $modal-authorization;
  position: fixed;
  left: 0%;
  bottom: 0;
  width: 100%;

  @media (min-width: $xs) {
    -webkit-transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    max-width: 403px;
    width: 100%;
  }
}

.authorizationContainer {
  border-radius: 16px 16px 0 0;

  padding: 24px 16px;
  background-color: $white;

  @media (min-width: $xs) {
    padding: 32px 20px;
    border-radius: 16px;
  }
}

.authorizationTitle {
  font-size: 20px;
  font-weight: 500;

  @media (min-width: $xs) {
    font-style: 28px;
  }
}

.authorizationSubTitle {
  font-size: 14px;
  line-height: 140%;
  color: $telegray;
  margin-top: 8px;

  @media (min-width: $xs) {
    font-size: 18px;
    letter-spacing: -0.32px;
  }
}

.authorizationInput {
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  padding: 14px 0 14px 16px;
  margin-top: 16px;
  border: 1px solid $telegray;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

.authorizationGetCodeButton {
  padding: 12px 0;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 120%;
  margin-top: 8px;

  @media (min-width: $xs) {
    font-size: 18px;
  }
}

.buttonBorder {
  border: 1px solid $white-aluminum;
}

.inputWrapper {
  margin: 10px 0;
}

.authorizationPrivacyPolicy {
  line-height: 140%;
  font-size: 14px;
  color: $telegray;
  margin-top: 16px;

  span {
    color: $dark-bluishGreen;
  }

  @media (min-width: $xs) {
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 130%;
  }
}

.authorizationInputWrapper {
  width: 100%;
  justify-content: space-between;
  margin-top: 8px;

  :global {
    .vi__container {
      width: 100%;
    }

    .vi__character {
      text-align: center;
      max-width: 25%;
      font-size: 28px;
      font-weight: 700;
      line-height: 1.8;
      border: none;
      border-bottom: 1px solid $gainsborough;

      &:focus {
        outline: none;
      }
    }

    .vi__character--selected {
      outline: none;
      border-block-color: $black;
    }

    .vi__character--inactive {
      background: transparent;
    }
  }
}

.authorizationEnterCodeInput {
}

.authorizationSendAgainContainer {
  display: flex;
  font-size: 14px;
  margin-top: 12px;

  @media (min-width: $xs) {
    font-size: 16px;
  }
}

.authorizationSendAgain__time {
  margin-left: 5px;
  color: $dark-bluishGreen;
}

.resendCode {
  border: 0;
  outline: none;
  background: transparent;
  color: $dark-bluishGreen;

  &:hover {
    color: lighten($dark-bluishGreen, 10%);
  }
}

.authorizationClose {
  position: absolute;
  top: 16px;
  right: 16px;
}

.authorizationBackButton {
  padding-top: 0;
  display: flex;
  align-items: center;
  margin: 0 0 16px -10px;
  font-size: 14px;
  color: $telegray;

  @media (min-width: $xs) {
    font-size: 16px;
  }
}

.authorizationEnterCodeInput::-webkit-inner-spin-button,
.authorizationEnterCodeInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
