@import 'src/styles/colors_v2';
@import 'src/styles/breakpoints';
@import 'src/styles/z_index';

$name-height: 46px;

.card {
  color: $white;
  border: 1px solid $smoky-white;
  border-radius: 20px;
  background-color: $white;
  position: relative;
  font-weight: 500;
  box-sizing: border-box;
  height: 100%;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: 1024px) {
    min-width: 242px;
  }

  @media (max-width: $sm) {
    min-width: 160px;
  }

  @media (min-width: $sm) {
  }
}

.productCardTopBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 8px;
  min-height: 168px;
  border-radius: 20px;

  @media (min-width: $sm) {
    padding: 7px 12px 12px 12px;
  }
}

.productImg {
  position: absolute;
  z-index: $z-0;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
}

.popup {
  width: 100%;
  position: absolute;
  z-index: $z-10;
  top: 0;
  left: 0;
  border-radius: 20px;
  background-color: $black;
  opacity: 0.5;
  height: 100%;
}

.activeBackground {
  background-color: rgba($color: $blackish-blue, $alpha: 0.4);
}

.productCardTags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  position: relative;
  z-index: $z_10;
}

.productCardTagsInfo {
  display: flex;
  align-items: center;
  position: relative;
  z-index: $z_10;
}

.productCardReviews {
  padding: 6px 10px 6px 23px;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  justify-content: space-between;
  color: $very_dark-greenish-blue;
  background-color: $white;
  border-radius: 100px;

  &::before {
    content: ' ';
    background: url('../../../public/icons2/yellow-star.svg') no-repeat center center/cover;
    position: absolute;
    top: 6px;
    left: 4px;
    width: 14px;
    height: 14px;
    margin-right: 20px;
  }

  @media (max-width: $sm) {
    font-size: 10px;
    padding: 5px 8px 5px 20px;

    &::before {
      top: 4px;
      left: 4px;
      width: 10.5px;
      height: 10px;
    }
  }
}

.productCardDiscounts {
  display: none;

  @media (min-width: $sm) {
    font-size: 12px;
    background-image: url('../../../public/icons2/discounts-product_cart.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 48px 24px;
    padding: 5.5px 0 5.5px 0;
    margin-left: 4px;
    display: block;
    min-width: 48px;
  }
}

.productCardStock {
  display: none;

  @media (min-width: $sm) {
    font-size: 12px;
    background-image: url('../../../public/icons2/stocks-product_cart.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 62px 24px;
    padding: 5.5px 5.5px;
    min-width: 62px;
    margin-left: 4px;
    display: block;
  }
}

.favoriteButton {
  background-color: transparent;
  padding: 12px;

  @media (max-width: $sm) {
    padding: 8px;
  }
}

.iconSm {
  width: 20px;
  height: 20px;
  stroke: $white;

  @media (min-width: $sm) {
    width: 24px;
    height: 24px;
  }
}

.productCardDaysOfWeek {
  padding-top: 104px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: $z_10;

  @media (min-width: $sm) {
    padding-top: 185px;
  }
}

.dayOfWeek {
  text-align: center !important;
  color: $light-telegray;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 100px;
  margin-right: 2px;
  opacity: 0.6;
  font-size: 10px;
  max-height: 13px;
  max-width: 20px;
  min-width: 18.5px;
  width: 100%;
  padding: 3px 0;
  font-weight: 500;
  line-height: 15.6px;
  text-transform: uppercase;

  @media (min-width: $sm) {
    padding: 4.5px 0;
    max-width: 34px;
    width: 100%;
    text-align: center;
    border-radius: 90px;
    font-size: 12px;
  }
}

.activeDay {
  text-align: center;
  background-color: $white;
  opacity: 1;
  color: $dark-bluishGreen;
}

.productCardBottomBlock {
  text-align: left;
  color: $black;
  margin: 8px;
  font-size: 14px;

  @media (min-width: $sm) {
    margin: 12px;
    font-size: 18px;
  }
}

.productCart__itemTop {
  font-weight: 600;
  min-height: 54px;

  @media (min-width: $sm) {
    min-height: 73px;
  }
}

.productCartTitle {
  color: $very_dark-greenish-blue;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;

  @media (min-width: $sm) {
    max-width: 252px;
  }
}

.productCartGrammar {
  margin-top: 4px;
  color: $telegray;
}

.productCardPricePurchase {
  margin-top: 8px;
}

.productCartPastPrice {
  position: relative;
  max-width: 71px;
  color: $telegray;

  font-weight: 400;
  display: inline-block;

  padding: 0 4px;

  &::after {
    content: '';
    position: absolute;
    top: 7px;
    left: 0;
    right: 0;
    border-bottom: 1px solid $telegray;
    transform: rotate(-10deg);

    @media (min-width: $sm) {
      bottom: 25px;
      max-width: 100%;
    }
  }

  @media (max-width: $sm) {
    font-size: 14px;
  }
}

.productPastPriceRuble {
  margin-left: 4px;
}

.productCardPurchase {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
  margin-top: -10px;

  .productCardDiscountPrice {
    position: relative;
    display: flex;
    align-items: center;
    color: $deep_yellow-pink;
    font-weight: 600;

    @media (min-width: $sm) {
      font-size: 28px;
      background-size: 25px;
      background-position: center left 50px;
    }
  }

  @media (min-width: $sm) {
    font-size: 28px;
  }
  span {
    margin-left: 5px;
  }
}

.priceWithoutDiscount {
  align-items: flex-end;
}

.productCardQuantitySelection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  z-index: $product-card-quantity;
  height: 100% button {
    padding: 0;
  }

  @media (min-width: $sm) {
    button {
      padding: 12px 8px;
    }
  }
}

.quantitySelectionText {
  font-size: 16px;

  @media (min-width: $sm) {
    font-size: 32px;
  }
}

.unitType {
  margin-left: 5px;
}

.svgDiscountRubl {
  position: absolute;
  top: -8px;
  left: 110%;
  width: 25px;
  height: 45px;

  @media (min-width: $sm) {
    width: 35px;
    height: 55px;
    top: -4px;
  }
}

.svgPastPriceRublSm {
  margin-top: 1px;
  padding-left: 5px;
  width: 20px;
  height: 25px;

  @media (min-width: $sm) {
    margin-top: 3px;
    width: 30px;
    height: 30px;
  }
}

.BasketButtonIcons {
  width: 20px;
  height: 20px;

  @media (min-width: $sm) {
    width: 36px;
    height: 36px;
  }
}

.productCardBasketButtonSm {
  border-radius: 16px;
}
