@import '../../styles/colors_v2.scss';
@import '../../styles/breakpoints.scss';
@import 'src/styles/z_index';

.wrapper {
  background-color: $white;
  width: 100%;
  z-index: $modal-delivery-day;
  position: fixed;
  left: 0%;
  bottom: 0;
  border-radius: 16px 16px 0 0;
  max-height: 650px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: $xs) {
    -webkit-transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    border-radius: 16px;

    max-width: 403px;
    bottom: auto;
  }
}

.popupMenu {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $popup-modal;
  background-color: rgba(0, 0, 0, 0.4);
}

.headerBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalContainer {
  padding: 22px 20px 20px;
  height: 100%;
  box-sizing: border-box;

  @media (min-width: $sm) {
    position: relative;
  }
}

.title {
  font-weight: 700;
  line-height: 26px;
  font-size: 24px;

  @media (min-width: $sm) {
    font-size: 32px;
    line-height: 28px;
  }
}

.closedModalDelivery {
  padding: 0;

  @media (min-width: $sm) {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}

.deliveryLocationContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 16px;

  @media (min-width: $sm) {
    align-items: center;
  }
}

.deliveryLocationLeftBlcok {
  display: flex;
  align-items: center;
}

.deliveryLocationText {
  margin-left: 8px;
  color: $white-aluminum;
  font-size: 14px;
  line-height: 17px;

  span {
    font-weight: 600;
    color: $black;
  }

  p {
    color: $black;
  }

  @media (min-width: $sm) {
    font-size: 16px;
    line-height: 22px;
  }
}

.selectionDeliveryDay {
  padding-top: 12px;

  input {
    box-sizing: border-box;
    width: 100%;
    background-color: $white-transparent;
    border: none;

    &:focus {
      outline: none;
    }
  }
}

.selectionDeliveryDayInput {
  background-image: url('../../../public/icons2/chevron-bottom.svg');
  background-repeat: no-repeat;
  background-position: right 18px center;
  padding: 16px 0 16px 12px;
  background-color: $white-transparent;
  border-radius: 12px;
}

.inputActive {
  border-radius: 12px 12px 0 0;
  background-image: url('../../../public/icons2/chevron-top.svg');
  background-repeat: no-repeat;
  background-position: right 18px center;
}

.selectionDeliveryDayArr {
  border-top: 1px solid $gainsborough;
  background-color: $white-transparent;
  padding: 16px 12px 20px 12px;
  border-radius: 0 0 12px 12px;
  font-size: 14px;
  line-height: 20px;
}

.dayButton {
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  background-color: $white-transparent;
}

.dayButtonActive {
  font-size: 14px;
  line-height: 20px;
  padding: 0;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  color: $dark-bluishGreen;
  background-color: $white-transparent;
  font-weight: 600;
  background-image: url('../../../public/icons2/check, checmark.svg');
  background-repeat: no-repeat;
  background-position: left 95px top 1px;
}

.selectionDeliveryDayTitle {
  font-size: 14px;
  line-height: 20px;
  margin-top: 16px;

  @media (min-width: $sm) {
    font-size: 16px;
  }
}

.catalogButtonWrapper {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.catalogButton {
  background-color: $saffron-yellow;
  color: $black;
  width: 100%;
  margin-top: auto;
  border-radius: 8px;
  padding: 15px 8px;
  font-weight: 500;

  @media (min-width: $sm) {
    font-weight: 500;
    line-height: 22px;
  }
}

.dayDeliveryBlock {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inStockBlock {
  white-space: nowrap;
  display: flex;
  align-items: center;
  margin-right: 20px;
  color: $red;

  img {
    margin-left: 6px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  height: 100%;
}