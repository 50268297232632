@import 'src/styles/colors';
@import 'src/styles/colors_v2';

.container {
  position: relative;
  width: 100%;

  :global {
    .select {
      &__control {
        width: 100%;
        height: 50px;
        border: 0;
        background: $smoky-white3;
        border-radius: 8px;
        box-shadow: none;
      }

      &__control--is-focused {
        border-color: $green-medium;

        &:hover {
          border-color: $green-medium;
        }
      }

      &__menu {
        border: 0;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: none;
        background: $smoky-white3;
      }

      &__option--is-focused {
        background: $green-shift;
      }

      &__option--is-selected {
        background: $green-selected;
        color: $font-black;
      }

      &__multi-value {
        padding: 7px 10px;
        border-radius: 30px;
        background: $green;
        color: $white;
      }

      &__multi-value__label {
        color: $white;
      }

      &__control--is-disabled {
        opacity: 0.5;
      }
    }
  }
}

.stick {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  padding: 0 5px;
  left: 10px;
  background: $smoky-white3;
  color: $black;
  pointer-events: none;
}

.disabled {
  opacity: 0.5;
}

.stickActive {
  top: -5px;
  font-size: 12px;
  line-height: 11px;
  transform: none;
}

.error {
  :global {
    .select {
      &__control {
        border: 1px solid $red;
      }
    }
  }
}

.stickError {
  top: 35%;
  color: $red;
}
